import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Container, Row, Col, Breadcrumb } from 'react-bootstrap'

import Layout from '../components/layout'
import Seo from '../components/seo'
import Section from '../components/section'
import Prose from '../components/prose'

const PrivacyPage = () => {
  const data = useStaticQuery(graphql`
    query PrivacyQuery {
      site {
        siteMetadata {
          siteUrl
        }
      }
    }
  `)

  const socialImage = 'https://www.smorescout.com/images/social/smorescout-summary.png'
  const socialImageAlt = `S’more Scout`

  const metaDescription = ``

  return (
    <Layout>
      <Seo
        title="Privacy Policy"
        description={metaDescription}
        canonicalUrl={`${data.site.siteMetadata.siteUrl}/privacy/`}
        meta={[
          {
            property: 'og:type',
            content: 'website',
          },
          {
            property: 'og:image',
            content: socialImage,
          },
          {
            property: 'og:image:alt',
            content: socialImageAlt,
          },
          {
            name: 'twitter:card',
            content: 'summary',
          },
          {
            name: 'twitter:image',
            content: socialImage,
          },
          {
            name: 'twitter:image:alt',
            content: socialImageAlt,
          },
        ]}
      />

      <Section>
        <Container className="pb-5">
          <Row className="justify-content-between align-items-center">
            <Col xs={12} md={10} xl={8}>
              <Breadcrumb>
                <Breadcrumb.Item
                  href="/"
                  linkProps={{ className: "breadcrumb-link" }}
                >
                  Home
                </Breadcrumb.Item>
              </Breadcrumb>

              <Prose>
                <h1>Privacy Policy</h1>

                <p className="lead">
                  When you visit our website at SmoreScout.com, we may collect
                  personal data. The purpose of this Privacy Policy is to
                  disclose what type of data we collect, where it is stored,
                  and how S’more Scout uses it.
                </p>

                <h2>
                  Personal data we collect
                </h2>
                <p>
                  We may obtain personal data direct from you and from various
                  other sources.
                </p>
                <p>
                  Here are some examples of when we may collect your data:
                </p>
                <ul>
                  <li>
                    Registering for events
                  </li>
                  <li>
                    Marketing or promotional activities
                  </li>
                  <li>
                    Accessing certain sections of the website
                  </li>
                  <li>
                    Subscribing to newsletters or press distribution lists
                  </li>
                  <li>
                    Participating in online surveys, discussion groups, forums,
                    or live chats
                  </li>
                  <li>
                    Entering contests
                  </li>
                </ul>

                <p>
                  We may collect the following information about you:
                </p>

                <ul>
                  <li>
                    Your contact details (such as your name, address, phone
                    number, and email address)
                  </li>
                  <li>
                    Information about how you use our website (for example,
                    traffic data, location data, and other communication data)
                  </li>
                  <li>
                    Information about your computer (including where available
                    your IP address, operating system, and browser type)
                  </li>
                  <li>
                    Correspondence with you
                  </li>
                </ul>

                <h2 id="data-use">
                  How we use the personal data we collect
                </h2>
                <p>
                  We may use the personal data we collect to record and support
                  your participation in the activities you select and
                  facilitate your relationship with us.
                </p>
                <p>
                  For instance, we may use such personal data to accomplish the
                  following:
                </p>
                <ul>
                  <li>
                    Provide products or services you request
                  </li>
                  <li>
                    Contact you regarding the contest that you decide to enter
                    or prize awards
                  </li>
                  <li>
                    Provide you with customer support
                  </li>
                  <li>
                    Enable you to participate in marketing, advocacy, or
                    promotional programs
                  </li>
                  <li>
                    Contact you and make you aware of other products,
                    information and services that we believe may be of interest
                    to you (subject to your consent and ability to unsubscribe
                    as described below)
                  </li>
                  <li>
                    Process and maintain a record of your transactions
                  </li>
                  <li>
                    Operate, evaluate, and improve our business and the products
                    and services we offer
                  </li>
                  <li>
                    Analyze and enhance our marketing communications and
                    strategies (including by identifying when emails sent to you
                    have been received and read)
                  </li>
                  <li>
                    Analyze trends and statistics regarding visitors’ use of the
                    website
                  </li>
                  <li>
                    Protect against and prevent fraud or violations of our terms
                    of services
                  </li>
                  <li>
                    Comply with applicable legal requirements
                  </li>
                </ul>

                <p>
                  If we intend to process your personal data in other ways, we
                  will provide specific notice at the time of collection.
                </p>
                <p>
                  In addition, subject to your ability to object as described
                  below, we may use personal data collected on the website to
                  build user profiles. In particular, if you decide to fill out
                  a web form or click on a traceable link, we may associate your
                  browsing activity on the website with your profile, which can
                  include your name and email address. We may use such profiles
                  for various purposes, including advertising, market research,
                  sales, and website design. If you wish that your personal data
                  is not used in such a manner, please send an email to{' '}
                  <a href="mailto:chris@smorescout.com">
                    dataprotection@smorescout.com
                  </a>.
                </p>

                <h2 id="legal">
                  Legal grounds for using your personal information
                </h2>
                <dl>
                  <dt>Contract</dt>
                  <dd>
                    Where we have an agreement with you as an individual, we
                    will use your personal information where necessary for the
                    purposes of that agreement. Importantly, if you do not
                    provide us with enough personal information for the purposes
                    of the agreement, we may not be able to enter into or
                    fulfill our obligations to you under the agreement.
                  </dd>

                  <dt>Legitimate interests</dt>
                  <dd>
                    In many situations, we will not have an agreement with you
                    as an individual. Where we do not have an agreement with you
                    as an individual and we have not asked for your consent, our
                    ground for the use of your personal information is for the
                    legitimate interests of our business. We will always
                    consider your rights and your privacy before using your
                    personal data for the legitimate interests of our business.
                    Where your rights or privacy override our business
                    interests, we will not use your personal data.
                  </dd>

                  <dt>Direct marketing</dt>
                  <dd>
                    If we obtained your details in the context of a sale of
                    goods or services, we will send you direct marketing unless
                    you object. If we did not obtain your details in the context
                    of a sale, we will only send you direct marketing if you
                    give us consent. You may request to
                    &ldquo;Unsubscribe&rdquo; at any time after receiving a
                    marketing communication from us. All marketing
                    communications that you receive from S’more Scout will
                    instruct you how to decline further marketing communications
                    from us.
                  </dd>

                  <dt>Consent</dt>
                  <dd>
                    There may be other specific situations where the appropriate
                    ground for us to use your personal data is your consent. We
                    will always make you aware when we are relying on your
                    consent to use your personal data.
                  </dd>

                  <dt>Legal reasons</dt>
                  <dd>
                    Separately from our business purposes, we will also use your
                    personal information where we have a legal obligation to do
                    so. For example, if a regulator or official authority asks
                    us for your personal information. In very limited
                    situations, we may also use your personal information to
                    exercise our legal rights (including in relation to legal
                    proceedings).
                  </dd>

                  <dt>Purpose limitation</dt>
                  <dd>
                    If we collect your personal data for one purpose and then
                    intend to use it for a different incompatible purpose, we
                    will seek your consent to do so and/or inform you where
                    necessary or appropriate.
                  </dd>
                </dl>

                <h2 id="share">
                  Personal data we share
                </h2>
                <p>
                  We do not disclose personal data about you except as described
                  in this Privacy Policy.
                </p>

                <dl>
                  <dt>Processors</dt>
                  <dd>
                    <p>
                      We may use a number of organizations to carry out a range
                      of services on behalf of S’more Scout
                      (&ldquo;processors&rdquo;).
                    </p>
                    <p>
                      Our processors must handle your personal data in
                      accordance with data protection law. We also have
                      agreements in place with our processors so that they only
                      handle your personal data as we have instructed them to.
                      They cannot make any other use of your personal
                      information (except where required by law).
                    </p>
                  </dd>

                  <dt>Legal reasons</dt>
                  <dd>
                    We may also share your personal information with regulators
                    or official authorities where required or where necessary to
                    exercise our legal rights.
                  </dd>
                </dl>

                <h2 id="retention">
                  How long we keep personal information
                </h2>
                <p>
                  To ensure we are able to meet our legal obligations and
                  exercise our legal rights, we will store your personal
                  information for 6 years after we last used it (or longer where
                  we have a specific legal obligation).
                </p>

                <h2 id="eea">
                  Transferring personal data outside the European Economic Area
                </h2>
                <p>
                  Many of our processors we work with are based outside of the
                  European Economic Area (EEA). When we send your personal
                  information outside of the EEA to a country not recognized as
                  providing appropriate protections, we put in place appropriate
                  contracts, group agreements, or other safeguards to ensure
                  your rights are protected.
                </p>

                <h2 id="cookies">
                  Cookies
                </h2>
                <p>
                  When you visit the website, we may collect certain information
                  by automated means, such as cookies, web beacons, local
                  storage, and other similar technologies.
                </p>
                <p>
                  Cookies are text files that websites send to a visitor’s
                  computer or other Internet-connected device to assign each
                  visitor a unique, randomly generated number that resides on
                  the visitor’s computer.
                </p>
                <p>
                  Cookies and similar technologies are widely used in order to
                  make websites work, or work more efficiently, as well as to
                  provide information to the owners of the site.
                </p>
                <p>
                  Most web browsers allow some control of most cookies through
                  the browser settings. To find out more about cookies,
                  including how to see what cookies have been set and how to
                  manage and delete them, visit
                  <a href="https://www.aboutcookies.org/">AboutCookies.org</a>
                  {' '}or{' '}
                  <a href="https://www.allaboutcookies.org/">AllAboutCookies.org</a>.
                </p>
                <p>
                  We use the following cookies on our website.
                </p>

                {/*
                <h3>Essential cookies</h3>
                <p>
                  These cookies are strictly necessary to provide you with
                  services available through our websites and to use some of its
                  features, such as access to secure areas. Because these
                  cookies are strictly necessary to deliver the websites, you
                  cannot refuse them without impacting how our websites
                  function. You can block or delete them by changing your
                  browser settings.
                </p>
                */}

                {/*
                <h3>Performance and functionality cookies</h3>
                <p>
                  These cookies are used to enhance the performance and
                  functionality of our websites but are non-essential to their
                  use. However, without these cookies, certain functionality may
                  become unavailable.
                </p>
                */}

                <h3>Analytics and customization cookies</h3>
                <p>
                  These cookies collect information that is used either in
                  aggregate form to help us understand how our websites are
                  being used or how effective our marketing campaigns are, or to
                  help us customize our websites and application for you in
                  order to enhance your experience.
                </p>

                <dl>
                  <dt>
                    Google Tag Manager: <code>NID</code>, <code>1P_JAR</code>
                  </dt>
                  <dd>
                    Tooling provided by Google to enable analytics
                    functionality.
                  </dd>

                  <dt>
                    Google Analytics: <code>_gat</code>, <code>_gid</code>,{' '}
                    <code>_ga</code>
                  </dt>
                  <dd>
                    Gathers information allowing us to understand customer
                    interactions with our websites and ultimately refine that
                    experience to better serve you.
                  </dd>
                </dl>

                {/*
                <h3>Advertising (targeting) cookies</h3>
                <p>
                  These cookies are used to make advertising messages more
                  relevant to you and your interests. They also perform
                  functions like preventing the same ad from continuously
                  reappearing, ensuring that ads are properly displayed, and in
                  some cases selecting advertisements that are based on your
                  interests.
                </p>
                */}

                <h2 id="updates">
                  Updates to this privacy policy
                </h2>
                <p>
                  This Privacy Policy may be updated periodically and without
                  prior notice to you to reflect changes in our personal data
                  practices.
                </p>
                <p>
                  Our use of personal data gathered while the current Privacy
                  Policy is in effect will be consistent with the current
                  statement, even if we change that statement later.
                </p>

                <h2>Your rights</h2>
                <p>
                  It is important that you are familiar with and easily able to
                  exercise the following rights:
                </p>
                <ul>
                  <li>
                    The right to be informed personal data relating to you is
                    being used or stored (which is what this Privacy Policy is
                    for)
                  </li>
                  <li>
                    The right to access your personal data
                  </li>
                  <li>
                    The right to object to direct marketing
                  </li>
                  <li>
                    The right to object to the use of your personal data carried
                    out on the ground of legitimate interests
                  </li>
                  <li>
                    The right to withdraw consent
                  </li>
                  <li>
                    The right to erasure of your personal data
                  </li>
                  <li>
                    The right of data portability
                  </li>
                  <li>
                    The right to have your personal data rectified if it is
                    inaccurate
                  </li>
                  <li>
                    The right to have your personal data restricted or blocked
                    from being used
                  </li>
                  <li>
                    The right to complain to the relevant data protection
                    authority
                  </li>
                </ul>
                <p>
                  Please be aware that these rights are not always absolute, and
                  there may be some situations in which you cannot exercise them
                  or they are not relevant. To help you understand these rights,
                  you may wish to look at the websites of the{' '}
                  <a href="https://edpb.europa.eu/">European Data Protection
                  Board</a> and the{' '}
                  <a href="https://edpb.europa.eu/about-edpb/board/members_en">
                  national data protection authorities</a>.
                </p>
                <p>
                  To exercise any of these rights, please use the contact
                  details provided below or email{' '}
                  <a href="mailto:dataprotection@smorescout.com">
                    dataprotection@smorescout.com
                  </a>.
                </p>

                <h2 id="dpo">
                  Data protection officer
                </h2>
                <p>
                  You may contact our data protection officer via email:{' '}
                  <a href="mailto:dataprotection@smorescout.com">
                    dataprotection@smorescout.com
                  </a>.
                </p>

                <h2 id="contact">
                  How to contact us
                </h2>
                <p>
                  If you have any questions about this Privacy Policy, they
                  should be directed to S’more Scout by sending a notice by
                  U.S. Mail to the following address:
                </p>
                <address>
                  S’more Scout<br />
                  Attention: Data Protection Officer<br />
                  19 Ardmore Road<br />
                  Springfield, Ohio 45504
                </address>

                <p>
                  Version: 20200729<br />
                  Effective: July 29, 2020<br />
                  Updated: July 30, 2020
                </p>
              </Prose>
            </Col>
          </Row>
        </Container>
      </Section>
    </Layout>
  )
}

export default PrivacyPage
